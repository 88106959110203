<template>
	<div style="background: #fff">
		<div style="background: #fff;">
			<div class="back">
				<div class="text">
					<img class="logo" :src="logo" alt="">
					<div class="title">{{title}}</div>
				</div>
				<img :src="topImg" alt="">
			</div>
			<div class="navigation">
				<ul class="ul">
					<li v-for="(item,index) in navList" :key="index" @click="goDingyue(index + 1,item.name,item)">
						{{item.name}}
					</li>
				</ul>
			</div>
		</div>
		<div v-if="display">
			<div class="lun" v-show="cards && cards.length > 0">
				<carousel :imgList="cards" :type="2" :width="(width - 24)" height="200PX" borderradius="0"
					@getclick="jump" background="rgb(0, 0, 0,0.38)"></carousel>
			</div>
			<div style="height: 20PX;background: #fff;" v-show="cards && cards.length <= 0"></div>

			<div class="right-focue">
				<div class="backed-center">
					<span class="span1">{{ medalyear }}年度奖牌榜</span>
					<p class="span2" v-if="!this.$verification(statisticalTime)">统计截至:{{statisticalTime}}</p>
				</div>
				<el-table :data="tableData" border>
					<el-table-column label="排名" align="center" width="50">
						<template slot-scope="scope">
							<div v-if="scope.$index == 0" class="custom-header"><img
									src="../../../assets/eventtheme/one.png" alt=""></div>
							<div v-else-if="scope.$index == 1" class="custom-header"><img
									src="../../../assets/eventtheme/two.png" alt=""></div>
							<div v-else-if="scope.$index == 2" class="custom-header"><img
									src="../../../assets/eventtheme/three.png" alt=""></div>
							<div v-else>
								{{ scope.$index + 1 }}
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="areaName" label="单位" align="center">
					</el-table-column>
					<el-table-column prop="first" label="金牌" align="center" width="50">
					</el-table-column>
					<el-table-column prop="second" label="银牌" align="center" width="50">
					</el-table-column>
					<el-table-column prop="third" label="铜牌" align="center" width="50">
					</el-table-column>
					<el-table-column prop="total" label="奖牌总数" align="center">
					</el-table-column>
				</el-table>
			</div>

			<section class="idx-article-sec idx-zwh idx-content2" v-for="(item,index) in allData" :key="index" v-if="allData && allData.length > 0 ">
				<div v-for="(res,index1) in item" :key="index1"
					v-if="item && item.length > 0 && res.name != '精彩瞬间' && res.name != '赛程安排' & res.name != '精彩图集' ">
					<h1 class="idx-h1">
						<span class="idx-tit">{{res.name}}</span>
						<span class="idx-more" @click="goDingyue(res.name,res)">更多&gt;&gt;</span>
					</h1>
					<ul class="idx-article-ul">

						<li v-for="(ess,index3) in res.articles" :key="index3" v-if="index3<7 && ess" @click="jump(ess.id)">
							<a>
								<span class="idx-article-tit">{{ess.title}}</span>
								<span class="idx-article-time">{{ess.publishTime | format}}</span>
							</a>
						</li>
					</ul>
				</div>
			</section>

			<div class="idx-content2">
				<h1 class="idx-h2">赛程一览</h1>
				<section class="idx-article-secc" id="event_basicInfo">
					<el-table :data="scheduleList" border >
						<el-table-column prop="name" label="赛事名称" align="center">
							<template slot-scope="scope">
								<div class="getname" @click="getname(scope.row.name)">{{ scope.row.name }}</div>
							</template>
						</el-table-column>
						<el-table-column prop="address" label="地点" align="center">
						</el-table-column>
						<el-table-column prop="createTime" label="时间" align="center">
							<template slot-scope="scope">
								<div v-if="scope.row.isJSON">
									<div v-for="(item,index) in scope.row.start_time" >
									  {{getTime(item)}}
									</div>
								</div>
								<div v-if="!scope.row.isJSON">
									{{scope.row.start_time | format}}
								</div>
							</template>
						</el-table-column>
					</el-table>
				</section>
			</div>
			
			<section class="idx-article-sec idx-zwh idx-content2" >
				<div >
					<h1 class="idx-h1">
						<span class="idx-tit">精彩瞬间</span>
						<span class="idx-more" @click="goDingyue2('精彩瞬间',id)">更多&gt;&gt;</span>
					</h1>
					<div style="margin:20PX 0;">
						<div>
							<div class="swiper" v-if="momentsList.length>0">
								<img src="../../../assets/eventtheme/theleftside.png" class="theleftside" alt="">
								<swiper :options="swiperOption" ref="mySwiper">
									<swiper-slide v-for="(item,index) in momentsList" :key="index">
										<div class="information" @click="jump(item.id,1)">
											<div class="title">{{item.title}}</div>
											<img class="img"
												:src="item.imgUrl"
												alt="">
										</div>
									</swiper-slide>
								</swiper>
								<img src="../../../assets/eventtheme/therightside.png" class="therightside" alt="">
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
		<div v-else>
			<div style="height: 40PX;background: #fff;"></div>
			<div style="background: #fff;">
				
				<section class="idx-article-sec idx-zwh idx-content2" >
					<div >
						<h1 class="idx-h1">
							<span class="idx-tit">{{concentratetitle}}</span>
							<span class="idx-more"></span>
						</h1>
						<ul class="idx-article-ul">
				
							<li v-for="(res,index) in concentrateList" :key="index" @click="jump2(res.id,concentratetitle)">
								<a>
									<span class="idx-article-tit">{{res.title}}</span>
									<span class="idx-article-time">{{res.publishTime | format}}</span>
								</a>
							</li>
						</ul>
					</div>
					
					<div class="paging" v-if="concentrateList.length > 0">
						<pagination :total="total" :type="2" @currentchange="currentchange" :go="10" :pageSize="10">
						</pagination>
					</div>
					
				</section>
			</div>
		</div>
	</div>
</template>

<script>
	import Vue from 'vue'
	import Meta from 'vue-meta'
	Vue.use(Meta)
	import {
		Swiper,
		SwiperSlide
	} from 'vue-awesome-swiper'
	import carousel from '@/components/carousel.vue'
	export default {
		name: '',
		components: {
			carousel,
			Swiper,
			SwiperSlide,
		},
		data() {
			return {
				logo: '',
				topImg: '',
				title: '',
				navList: [],
				concentratetitle: '',
				display: true,
				info: {
					id: '',
					pageNum: 1,
					pageSize: 10,
				},
				concentrateList: [],
				total: 0,
				cards: [],
				allData: [],
				tableData: [],
				articles: [],
				momentsList: [],
				scheduleList: [],
				swiperOption: {
					slidesPerView: 3, // 一屏显示的slide个数
					centeredSlides: true, // 居中的slide是否标记为active，默认是最左active,这样样式即可生效
					slideToClickedSlide: true, // 点击的slide会居中
					loop: true, // 循环播放, 可有无限滚动效果，初始加载即是滚动后的效果
					autoplay: {
						delay: 2000, //秒
						stopOnLastSlide: false,
						disableOnInteraction: false, //滑动不会失效
					},
					on: {
						// 该方法中的this都指代swiper本身
						tap: function() {
							console.log('点击的位置', this.activeIndex);
						}
					},
					navigation: {
						//下一张
						nextEl: '.therightside',
						//上一张
						prevEl: '.theleftside'
					}
				},
				imgUrl: '',
				id:'',
				statisticalTime:'',
				medalyear:new Date().getFullYear()
			}
		},
		computed: {
			width() {
				return Number(document.documentElement.clientWidth)
			}
		},
		filters: {
			format(x) {
				if (x != null && x != '') {
					return x.split(" ")[0]
				}
			}
		},
		mounted() {
			document.body.style.backgroundColor = '#fff';
		},
		created() {
			this.logo = this.$route.query.logo
			this.title = this.$route.query.title

			document.title = this.$route.query.title + '-北京市体育竞赛管理和国际交流中心'
			this.$meta().refresh()
			this.$getwxsdk(this.$route.query.title + '-北京市体育竞赛管理和国际交流中心')

			this.$store.commit("EXIT_STATIONID", this.$route.query.id)
			this.getNav()
			this.getTopBanner()
			this.getIndexImgList()
			this.getIndexColumn()
			this.matchList()
			this.medalRankingList()
		},
		activated() {
			this.$store.commit("EXIT_STATIONID", this.$route.query.id)
		},
		beforeRouteLeave(to, from, next) {
			document.body.style.backgroundColor = '#fff'
			this.$store.commit("EXIT_STATIONID", '1005012')
			next();
		},
		methods: {
			getNav() {
				this.$api.getNav().then(res => {
					this.navList = res.data.data
				})
			},
			getTopBanner() {
				this.$api.getTopBanner({
					type: '1'
				}).then(res => {
					this.topImg = res.data.data[0].imgUrl
				})
			},
			getIndexImgList() {
				this.$api.getIndexImgList().then(res => {
					this.cards = res.data.data
				})
			},
			jump(id,e) {
				if(e == 1){
					if (this.$store.state.token == '') {
						this.$confirm('请登录后查看更多信息', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							this.$store.commit("EXIT_TOKEN")
							this.$router.push({
								path: '/h5/login',
								query: {
									person: true,
									grp: false,
									refresh: new Date().getTime()
								}
							})
						}).catch(() => { });
						return
					}
				}
				this.$router.push({
					path: '/h5/H5articledetails',
					query: {
						id: id,
						refresh: new Date().getTime(),
					}
				})
			},
			jump2(id,title) {
				if(title == '精彩瞬间'){
					if (this.$store.state.token == '') {
						this.$confirm('请登录后查看更多信息', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							this.$store.commit("EXIT_TOKEN")
							this.$router.push({
								path: '/h5/login',
								query: {
									person: true,
									grp: false,
									refresh: new Date().getTime()
								}
							})
						}).catch(() => { });
						return
					}
				}
				this.$router.push({
					path: '/h5/H5articledetails',
					query: {
						id: id,
						refresh: new Date().getTime(),
					}
				})
			},
			goDingyue(e,title, row) {
				this.concentratetitle = title
				this.display = false
				this.info.id = row.id
				this.getArticleListById()
			},
			goDingyue2(title, id) {
				this.concentratetitle = title
				this.display = false
				this.info.id = id
				this.getArticleListById()
			},
			currentchange(val) {
				this.info.pageNum = val
				this.getArticleListById()
			},
			getArticleListById() {
				this.$api.getArticleListById(this.info).then(res => {
					this.concentrateList = res.data.data.list
					this.total = res.data.data.total
				})
			},
			getIndexColumn() {
				this.$api.getIndexColumn(this.$route.query.id).then(res => {
					this.allData = res.data.data
					this.articles = res.data.data[0].articles
					for (let i in res.data.data) {
						for (let x in res.data.data[i]) {
							if (res.data.data[i][x].name == '精彩瞬间') {
								this.momentsList = res.data.data[i][x].articles
								this.id = res.data.data[i][x].id
							}
						}
					}
					
					this.allData1.forEach(item=>{
						item.forEach(rss=>{
							rss.articles.forEach(css=>{
								css.title= css.title.replace(/<br>/g, ' ')
							})
						})
					})
					this.momentsList.forEach(item=>{
						item.title= item.title.replace(/<br>/g, ' ')
					})
				})
			},
			tableRowClassName({
				row,
				rowIndex
			}) {
				if (String(rowIndex / 2).indexOf(".") > -1) {
					return 'success-row';
				}
				return '';
			},
			matchList() {
				let data = {
					stationId: this.$route.query.id
				}
				this.$api.matchInfoListAll(data).then(res => {
					res.data.data.forEach(item => {
					  item.isJSON = this.isJSON(item.start_time)
					  if (this.isJSON(item.start_time)) {
					    item.start_time = JSON.parse(item.start_time)
					  }
					})
					this.scheduleList = res.data.data
				})
			},
			isJSON(str) { // 判断是否json
			  if (typeof str != 'string') { // 1、传入值必须是 字符串
			    return false;
			  }
			  try {
			    var obj = JSON.parse(str); // 2、仅仅通过 JSON.parse(str)，不能完全检验一个字符串是JSON格式的字符串
			    if (typeof obj == 'object' && obj) { //3、还必须是 object 类型
			      return true;
			    } else {
			      return false;
			    }
			  } catch (e) {
			    return false;
			  }
			  return false;
			},
			getTime(dates) { // 时间转换
			  let startDate = new Date(dates[0]);
			  let endDate = new Date(dates[1]);
			  let formattedStartDate =
			    `${startDate.getFullYear()}-${(startDate.getMonth() + 1).toString().padStart(2, '0')}-${startDate.getDate().toString().padStart(2, '0')}`;
			  let formattedEndDate =
			    `${endDate.getFullYear()}-${(endDate.getMonth() + 1).toString().padStart(2, '0')}-${endDate.getDate().toString().padStart(2, '0')}`;
			  if(formattedStartDate == formattedEndDate){
			  	return `${formattedStartDate}`;
			  }else{
			  	return `${formattedStartDate} 至 ${formattedEndDate}`;
			  }
			},
			medalRankingList() {
				this.$api.medalRankingList(this.$route.query.id,2).then(res => {
					this.tableData = res.data.data.rankingList
					this.statisticalTime = res.data.data.statisticalTime
					this.medalyear = res.data.data.year
				})
			},
			getname(row){
				let title = ''
				if(row == '2023年北京市青少年篮球锦标赛（甲组）'){
					title = row.replace('（甲组）', '')
				}else if(row == '2023年北京市青少年篮球锦标赛（乙组）'){
					title = row.replace('（乙组）', '')
				}else if(row == '2023年北京市青少年篮球锦标赛（丙组）'){
					title = row.replace('（丙组）', '')
				}else{
					title = row
				}

				let routeData = this.$router.resolve({
					path: '/h5/H5notice',
					query: {
						id: '847',
						pid:'720',
						refresh: new Date().getTime(),
						matchName:title,
						name:'登记/查询'
					}
				})
				window.open(routeData.href, '_blank');
			}
		}
	}
</script>

<style scoped lang="scss">
	.back {
		width: 100%;
		min-height: 174PX;
	}

	.back .text {
		position: absolute;
		top: 40PX;
		width: 100%;
		display: flex;
		align-items: center;
		flex-direction: column;
	}

	.back .text .logo {
		width: 94PX;
		height: 61PX;
	}

	.back .text .title {
		font-size: 17PX;
		font-weight: normal;
		color: #444444;
		margin-top: 10PX;
	}

	img {
		width: 100%;
		height: 100%;
	}

	.navigation {
		overflow-x: auto;
		position: absolute;
		position: absolute;
		// display: flex;
		// padding-bottom: 10PX;
		width: calc(100% - 40PX);
		margin: -14PX 20PX 0 20PX;

		ul {
			display: flex;
			justify-content: space-between;
		}

		li {
			font-size: 14PX;
			font-family: Microsoft YaHei;
			font-weight: bold;
			color: #fff;
			flex-shrink: 0;
			// margin-left: 20PX;
			padding: 5PX 10PX;
			background: linear-gradient(0deg, #144c82, #029f74);
			box-shadow: 1PX 1PX 0PX 0PX #FFFFFF;
			border-radius: 4PX;
		}

	}

	.navigation::-webkit-scrollbar {
		display: none;
	}

	.lun {
		padding: 30PX 14PX 10PX 14PX;

		.spot {
			position: absolute;
			margin-top: 126PX;
			width: 74PX;
			height: 72PX;
			// bottom: 60PX;
			margin-left: -54PX;
		}

		::v-deep .instBox {
			height: 40PX;
		}

		::v-deep .instBox_back {
			height: 40PX;
		}

		::v-deep .instBox_back .text {
			font-size: 15PX;
			margin-top: 10PX !important;
		}

		::v-deep .instBox_text1 {
			margin-top: -51PX !important;
		}
	}

	.head {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10PX 30PX;
		border-bottom: 1PX solid #E6E6E6;
		background: #fff;

		.zuo {
			display: flex;

			img {
				width: 22PX;
				height: 20PX;
			}

			.name {
				font-size: 14PX;
				font-family: Source Han Sans CN;
				font-weight: 500;
				margin-left: 10PX;
			}
		}

		.you {
			font-size: 12PX;
			font-family: Source Han Sans CN;
			font-weight: 500;
			color: #000000;
		}
	}

	.wzxs {
		padding: 0 28PX;
		background: #fff;
		padding-bottom: 20PX;

		.title {
			font-size: 14PX;
			font-family: Source Han Sans CN;
			font-weight: 500;
			color: #000000;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			line-clamp: 2;
			-webkit-box-orient: vertical;
		}

		.data {
			font-size: 12PX;
			font-family: Source Han Sans CN;
			font-weight: 400;
			color: rgba(0, 0, 0, 0.6);
			margin-top: 10PX;
		}
	}

	.xian {
		height: 7PX;
		background: #838383;
		opacity: 0.14;
	}

	.one {
		display: flex;
		justify-content: center;
		z-index: 99999;
		position: relative;
		margin-top: 40PX;
	}

	.one .but {
		margin-top: -62PX;
	}

	.one .but .name {
		width: 88PX;
		height: 30PX;
		font-size: 14PX;
		font-weight: 500;
		color: #266DFF;
		position: absolute;
		text-align: center;
		line-height: 30PX;
		margin-left: 13PX;
	}

	.one .but .backimg {
		width: 110PX;
		height: 30PX !important;
	}

	.back-box {
		width: 100%;
		background: linear-gradient(2deg, rgba(255, 255, 255, 0.27), rgba(23, 236, 255, 0.27));
		margin-top: -47PX;
		min-height: 200PX;
	}


	.table {
		padding-top: 50PX;
		padding-bottom: 50PX;
		// margin: 0 90PX;
		margin: 0 16PX;
		width: calc(100% - 32PX) !important;
		overflow-y: auto;
	}

	.table ::v-deep .el-table thead {
		color: #fff;
		font-size: 16PX;
		font-weight: 500;
		font-size: 11PX;
	}

	.table ::v-deep .el-table__header-wrapper {
		borderradius: 25PX;
	}

	.table ::v-deep .el-table {
		border-radius: 10PX;
		box-shadow: 0PX 0PX 15PX 1PX rgba(143, 217, 243, 0.34)
	}

	.custom-header {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.custom-header img {
		width: 15PX;
		height: 20PX;
		margin-right: 7PX;
		;
	}

	.table2 {
		padding-top: 50PX;
		padding-bottom: 50PX;
		// margin: 0 90PX;
		margin: 0 16PX;
		width: calc(100% - 32PX) !important;
		overflow-y: auto;
	}

	.table2 ::v-deep .el-table thead {
		color: #fff;
		font-size: 16PX;
		font-weight: 500;
		font-size: 11PX;
	}

	.table2 ::v-deep .el-table__header-wrapper {
		borderradius: 25PX;
	}

	.table2 ::v-deep .el-table {
		border-radius: 10PX;
		box-shadow: 0PX 0PX 15PX 1PX rgba(143, 217, 243, 0.34)
	}

	// .table2 ::v-deep .el-table thead tr th:nth-child(2){
	// 	background-image: linear-gradient(#F8B65C, #FFE71D, #FFBA5B) !important;
	// }
	// .table2 ::v-deep .el-table thead tr th:nth-child(3){
	// 	background-image: linear-gradient(#EEEDEC, #D1CCCA, #F0EFEE) !important;
	// }
	// .table2 ::v-deep .el-table thead tr th:nth-child(4){
	// 	background-image: linear-gradient(#FFE5BB, #DE9D76, #FFF7D9) !important;
	// }

	.swiper {
		width: 100%;
		display: flex;
		align-items: center;
	}

	.swiper-container {
		width: 100%;
		height: 100%;
	}

	.theleftside {
		width: 16PX;
		height: 19PX;
		// margin-right: 14PX;
		cursor: pointer;
	}

	.therightside {
		width: 16PX;
		height: 19PX;
		margin-left: 14PX;
		cursor: pointer;
	}

	.swiper ::v-deep .swiper-wrapper {
		display: flex;
		align-items: center
	}

	.swiper-slide {
		text-align: center;
		font-size: 18PX;

		/* Center slide text vertically */
		display: -webkit-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		-webkit-justify-content: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		-webkit-align-items: center;
		align-items: center;
		transition: 300ms;
		// margin-right: 5PX;
	}

	.swiper-slide-active,
	.swiper-slide-duplicate-active {
		transform: scale(1);
	}

	.information {
		width: 79PX !important;
		height: 60PX !important;
		position: absolute;
	}

	.information .title {
		width: 79PX !important;
	}

	::v-deep .swiper-slide-active {
		width: 116PX !important;
		margin: 0 10PX;
	}

	::v-deep .swiper-slide-active .information {
		width: 116PX !important;
		height: 86PX !important;
		position: relative !important;

	}

	::v-deep .swiper-slide-active .information .title {
		width: 116PX !important;
	}

	::v-deep .swiper-slide-active .information .img {
		width: 100%;
		height: 100%;
	}

	.information .title {
		position: absolute;
		width: 100%;
		height: 14PX;
		font-size: 9PX;
		font-weight: 500;
		color: #FFFFFF;
		bottom: 0;
		background: rgba(0, 0, 0, 0.4);
		border-bottom-left-radius: 16PX;
		border-bottom-right-radius: 16PX;
		line-height: 14PX;
		padding: 0 10PX;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		-o-text-overflow: ellipsis;
	}

	.information .img {
		width: 100%;
		height: 100%;
		border-radius: 16PX;
		border-radius: 16PX;
	}

	::v-deep .swiper-slide-active .information .title {
		height: 21PX !important;
		line-height: 21PX !important;
		font-size: 12PX !important;
	}

	.paging {
		padding-bottom: 10PX;
	}

	.paging span {
		margin-right: 5PX;
	}






	.idx-article-sec {
		margin: 10PX 14PX;
		padding-bottom: 10PX;
		height: auto;
		margin-right: 9PX;
		font-family: Source Han Sans CN;
		font-weight: 500;
		color: #000000;
	}

	.idx-h1 {
		background: #e3e3e3;
		height: 50PX;
		font-weight: 500;
		margin-bottom: 8PX;
		border-right: 8PX solid #ffa753;
	}

	.idx-tit {
		text-indent: 14PX;
		float: left;
		font-size: 25PX;
		color: #005ead;
		display: block;
		border-top: 3PX solid #ffa753;
		height: 51PX;
		line-height: 51PX;
		width: 153PX;
		background-color: #fff;
	}

	.idx-more {
		line-height: 50PX;
		width: 86PX;
		height: 50PX;
		text-align: right;
		padding-right: 0;
		margin-top: 0;
	}

	.idx-more {
		float: right;
		display: block;
		font-size: 16PX;
		color: #49689c;
		line-height: 50PX;
		width: 86PX;
		height: 50PX;
	}

	.idx-article-ul {
		font-size: 16PX;
	}

	.idx-article-ul li {
		margin-bottom: 10PX;
		height: 90PX;
		line-height: 32PX;
	}

	.idx-article-ul .idx-article-tit {
		float: left;
		display: inline-block;
		width: 100%;
		white-space: pre-wrap;
		ext-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		height: 60PX;
		overflow: hidden;
	}

	.idx-article-ul .idx-article-time {
		color: #939393;
		float: right;
		margin-right: 24PX;
	}


	.right-focue {
		max-height: 400PX;
		float: none;
		margin-top: 20PX;
		background-color: #eee;
		margin: 0 14PX;
		margin-right: 10PX;
		overflow: hidden;
		overflow-y: auto;
	}

	.backed-center {
		background: url('../../../assets/eventtheme/title1.png') left top no-repeat;
		background-size: cover;
		height: 42PX;
		line-height: 42PX;
		width: 100%
	}

	.span1 {
		font-size: 16PX;
		font-weight: 600;
		padding-left: 10PX;
	}

	.span2 {
		font-size: 14PX;
		color: #fff;
		line-height: 52PX;
		float: right;
		margin-right: 7PX;
	}
	
	
	.idx-content2 {
	    margin: 10PX 14PX;
	    margin-right: 10PX;
	}
	.idx-h2 {
	    font-size: 32PX;
	    width: 100%;
	    height: 60PX;
	    line-height: 60PX;
		background: linear-gradient(90deg, #01a673, #1c2789);
		color: #fff;
		text-align: center;
	}
	.idx-article-secc {
	    margin: 10PX 0;
	    padding-bottom: 10PX;
	    height: auto;
	    max-height: 364PX;
	    overflow: hidden;
	}
	#event_basicInfo {
	    overflow: hidden;
	    overflow-y: scroll;
	}
	.idx-article-secc {
	    max-height: 540PX;
	}
	.getname{
		color: #409eff;
	}
</style>